import React from "react"
import Container from "react-bootstrap/Container"

import Layout from "../components/Layout"
import Seo from "../components/SEO"

const contact = () => {
  return (
    <Layout className="section-contact">
      <Seo title="Contact" />
      <section className="section-contact__information">
        <Container className="container">
          <h3 className="section-contact__information__title">
            Request Information
          </h3>
          <div className="section-contact__information row">
            <div className="section-contact__information__form col-md-7">
              <form
                className="contact-form"
                action="https://formspree.io/f/xeqnpgzr"
                method="POST"
              >
                <div className="row g-2">
                  <div className="col-md-6">
                    <label htmlFor="firstName" className="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      className="form-control"
                      name="First name"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="lastName" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      className="form-control"
                      name="Last name"
                      required
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      name="Email"
                      required
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="checkIn" className="form-label">
                      Check In
                    </label>
                    <input
                      type="date"
                      id="checkIn"
                      className="form-control"
                      name="Check in"
                      required
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="checkOut" className="form-label">
                      Check Out
                    </label>
                    <input
                      type="date"
                      id="checkOut"
                      className="form-control"
                      name="Check out"
                      required
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="numPeople" className="form-label">
                      &#x23; People
                    </label>
                    <input
                      type="number"
                      id="numPeople"
                      className="form-control"
                      name="Num people"
                      required
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="message" className="form-label">
                      Message
                    </label>
                    <textarea
                      id="message"
                      rows="4"
                      className="form-control"
                      name="Message"
                      required
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <button type="submit" className="btn btn-primary w-100">
                      Submit request
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="
                section-contact__information__details
                col-md-5
                d-flex
                flex-column
                justify-content-center
              "
            >
              <div className="row">
                <div className="col-12">
                  <h4>Adriana Beach</h4>
                </div>
                <div className="col-12">
                  <p>Pefkohori Beachfront</p>
                </div>
                <div className="col-12">
                  <p>Halkidiki 63085, Greece</p>
                </div>
                <div className="col-12">
                  <a href="mailto:reservations@adrianabeach.gr">
                    reservations@adrianabeach.gr
                  </a>
                </div>
                <div className="col-12">
                  <a href="tel:+30-2374-061-501">+30 2374 061 501</a>
                </div>
                <div className="col-12">
                  <a href="tel:+30-6946-959-112">+30 6946 959 112</a>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="section-contact__map">
        <div className="container">
          <iframe
            src="https://snazzymaps.com/embed/316723"
            title="map"
            width="100%"
            height="500px"
            style={{ border: "none" }}
          ></iframe>
        </div>
      </section>
    </Layout>
  )
}

export default contact
